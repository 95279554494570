<section class="contenedor" [ngStyle]="currentStage.styles">
    <div class="contenido" aria-live="polite" >
        <h1 [@fadeAnimation]="state">
            {{ currentStage.text }} <br />
            <em [@underlineAnimation]="underlineState">{{
                currentStage.subtext
            }}</em>
        </h1>
    </div>
</section>
