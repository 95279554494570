import { Component } from '@angular/core';
import { SeccionCincoCarrusel } from './components/seccion-cinco-carrusel/seccion-cinco-carrusel.component';

@Component({
    selector: 'app-home-seccion-cinco',
    standalone: true,
    imports: [SeccionCincoCarrusel],
    templateUrl: './home-seccion-cinco.component.html',
    styleUrl: './home-seccion-cinco.component.scss',
})
export class HomeSeccionCincoComponent {


    carouselItems = [
        {
            carrouselItemID: 0,
            carrouselItemTitle: 'Optimizando la venta B2B - Exintrader',
            carrouselItemImgURL: '/img/home/home-seccion-cinco-carrousel-three.png',
            carrouselItemText: [
            {
                textID: 1,
                text: 'Exintrader, líder en el comercio de equipos eléctricos y electrónicos para vehículos, nos convocó para rediseñar su plataforma B2B. La plataforma original, utilizada durante más de 15 años, requería una actualización significativa para satisfacer las demandas actuales. Mejoramos la integración con su ERP y optimizamos las herramientas de filtro y búsqueda de productos.'
            },
            {
                textID: 2,
                text: 'Este proyecto modernizó la plataforma de la compañía, integrando 15 años de experiencia en comercio B2B. La nueva herramienta sitúa a Exintrader en el primer nivel del comercio electrónico, ofreciendo una experiencia de usuario más eficiente y adaptada a las necesidades actuales de sus clientes, asegurando su liderazgo en el mercado.'
            },
            ],
        },
        {
            carrouselItemID: 2,
            carrouselItemTitle: 'La Transformación de la caja de jubilaciones de Córdoba',
            carrouselItemImgURL: '/img/home/home-seccion-cinco-carrousel-one.png',
            carrouselItemText: [
            {
                textID: 3,
                text: 'Durante seis años, trabajamos en modernizar la Caja de Jubilaciones de Córdoba, mejorando la liquidación de beneficios y optimizando los procesos de otorgamiento. Logramos reducir el tiempo de pensiones de seis meses a quince días y de jubilaciones de un año a un mes. Desarrollamos herramientas para gestionar la historia laboral y los aportes de los trabajadores.',
            },
            {
                textID: 4,
                text: 'Implementamos una plataforma web que permite a los aportantes y beneficiarios realizar sus trámites en línea, facilitando el acceso y la eficiencia. Desde la pandemia, la Caja opera completamente de forma remota, garantizando la continuidad del servicio sin atención presencial.',
            },
            ],
        },
        {
            carrouselItemID: 3,
            carrouselItemTitle: 'Innovando desde el campo - PlanUp',
            carrouselItemImgURL: '/img/home/home-seccion-cinco-carrousel-two.png',
            carrouselItemText: [
            {
                textID: 5,
                text: 'PlanUp es una plataforma innovadora para la venta consultiva de fitosanitarios, diseñada para transformar cómo los productores agrícolas gestionan sus cultivos. A través de esta herramienta, los usuarios ingresan información detallada sobre sus lotes, incluyendo características del suelo y tipos de cultivos. PlanUp genera una planificación personalizada para la aplicación de fitosanitarios, optimizando su uso.',
            },
            {
                textID: 6,
                text: 'La plataforma permite el seguimiento del calendario de aplicaciones, enviando notificaciones sobre qué productos aplicar, en qué dosis y momento del año. PlanUp también ofrece la posibilidad de solicitar y cotizar los insumos necesarios, garantizando que los productores dispongan de recursos adecuados en el momento preciso.',
            },
            ],
        },

        {
            carrouselItemID: 4,
            carrouselItemTitle: 'Digitalizando los servicios portuarios - LaBarquita',
            carrouselItemImgURL: '/img/home/home-seccion-cinco-carrousel-four.png',
            carrouselItemText: [
            {
                textID: 7,
                text: 'LaBarquita, destacada empresa de servicios portuarios, nos convocó para desarrollar una herramienta que digitalizara la gestión de servicios ofrecidos a buques que arriban al puerto de Rosario. Estos buques requieren una coordinación eficiente para asegurar operaciones sin contratiempos. La solución desarrollada optimizó la comunicación con los clientes y revolucionó el funcionamiento interno.'
            },
            {
                textID: 8,
                text: 'La herramienta mejoró la interacción con los clientes e informatizó todos los procesos internos. Con esta transformación digital, LaBarquita se convirtió en una empresa 100% digital, eliminando el uso de papel y mejorando la precisión y rapidez en su gestión. Esta digitalización posicionó a la compañía como un líder innovador en el sector portuario, preparado para enfrentar futuros desafíos.'            },
            ],
        },
    ];

}
