import { Component, HostListener } from '@angular/core';
import { SeccionTresCardComponent } from './components/seccion-tres-card/seccion-tres-card.component';
import { RouterLink } from '@angular/router';
import AOS from 'aos';


@Component({
    selector: 'app-home-seccion-tres',
    standalone: true,
    imports: [SeccionTresCardComponent, RouterLink],
    templateUrl: './home-seccion-tres.component.html',
    styleUrl: './home-seccion-tres.component.scss',
})
export class HomeSeccionTresComponent{

    private refreshAOS: boolean = true;

    @HostListener('window:scroll', ['$event']) // for window scroll events
    onScroll(event: any) {

        if (this.refreshAOS) {
            AOS.refresh(); // Actualiza AOS después de que se renderiza la vista
            this.refreshAOS = false;
        }
    }
    
}
