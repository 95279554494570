<footer class="contenedor">
    <div class="contenido">
        <div>
            <a routerLink="/" class="contenedorLogo">
                <img alt="logo" src="img/logo.svg" class="logo" />
            </a>
            <div>
                <div class="about">
                    <p>Sobre Kainet</p>
                    <ul class="list">
                        <li class="item">
                            <div>
                                <img alt="location" src="img/location.svg" />
                            </div>
                            Rosario, Argentina
                        </li>
                        <li class="item">
                            <a
                                href="https://wa.me/+5493415324679"
                                target="_blank"
                            >
                                <div>
                                    <img
                                        alt="whatsapp"
                                        src="img/whatsapp.svg"
                                    />
                                </div>
                                +5493415324679
                            </a>
                        </li>
                        <li class="item">
                            <div>
                                <img alt="mail" src="img/mail.svg" />
                            </div>
                            contacto&#64;kainet.dev
                        </li>
                    </ul>
                </div>
                <div class="site-map">
                    <p>Mapa de sitio</p>
                    <ul class="list">
                        <li>
                            <a
                                class="item"
                                routerLink="/"
                                routerLinkActive="active"
                                >Home</a
                            >
                        </li>
                        <li>
                            <a
                                class="item"
                                routerLink="/empresa"
                                routerLinkActive="active"
                                >Empresa</a
                            >
                        </li>
                        <li>
                            <a
                                class="item"
                                routerLink="/servicio"
                                routerLinkActive="active"
                                >Servicio</a
                            >
                        </li>
                        <li>
                            <a
                                class="item"
                                routerLink="/desarrollos"
                                routerLinkActive="active"
                                >Desarrollos</a
                            >
                        </li>
                        <li>
                            <a
                                class="item"
                                routerLink="/talento"
                                routerLinkActive="active"
                                >Talento</a
                            >
                        </li>
                        <!-- <li>
                            <a
                                class="item"
                                routerLink="/blog"
                                routerLinkActive="active"
                                >Blog</a
                            >
                        </li> -->
                    </ul>
                </div>
            </div>
        </div>
        <div>2024. Todos los derechos reservados.</div>
    </div>
</footer>
