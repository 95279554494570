import { NgStyle } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-card',
    standalone: true,
    imports: [NgStyle],
    templateUrl: './seccion-tres-card.component.html',
    styleUrl: './seccion-tres-card.component.scss',
})
export class SeccionTresCardComponent {
    @Input({ required: true }) cardNumber!: string;
    @Input({ required: true }) cardTitle!: string;
    @Input({ required: true }) cardImageURL!: string;
}
