import { Component } from '@angular/core';
import { CardContainerComponent } from '../../../base/card-container/card-container.component';
import { FormularioContactoComponent } from './components/formulario-contacto/formulario-contacto.component';

@Component({
    selector: 'app-home-seccion-seis',
    standalone: true,
    imports: [FormularioContactoComponent, CardContainerComponent],
    templateUrl: './home-seccion-seis.component.html',
    styleUrl: './home-seccion-seis.component.scss',
})
export class HomeSeccionSeisComponent {}
