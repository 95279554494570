import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { SelectorDeLenguageComponent } from './components/selector-de-lenguage/selector-de-lenguage.component';

@Component({
    selector: 'app-header',
    standalone: true,
    imports: [
        RouterLink,
        RouterLinkActive,
        CommonModule,
        SelectorDeLenguageComponent,
    ],
    templateUrl: './header.component.html',
    styleUrl: './header.component.scss',
})
export class HeaderComponent {
    public isMenuOpen = false;

    @Input()
    public headerBackground: string = 'transparent';


    toggleMenu(isLogo: boolean = false) {
        if (isLogo) {   
            this.isMenuOpen = false
            return
        }
        this.isMenuOpen = !this.isMenuOpen;
    }
}
