<div class="container" >
  <div class="number">
    <span>
      {{ cardNumber }}
    </span>
  </div>
  <div class="content">
    <div>
      <h4 class="card-title">{{ cardTitle }}</h4>
      <ng-content></ng-content>
    </div>
  </div>
  <div class="img-container" [ngStyle]="{'background-image': 'url('+cardImageURL+')'}">
  </div>
</div>
