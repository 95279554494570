import {
    trigger,
    state,
    style,
    transition,
    animate,
} from '@angular/animations';
import { NgStyle } from '@angular/common';
import { afterNextRender, ChangeDetectorRef, Component } from '@angular/core';

type Stage = {
    text: string,
    subtext: string,
    index: number,
    styles: any,
};

@Component({
    selector: 'app-home-seccion-uno',
    standalone: true,
    imports: [NgStyle],
    templateUrl: './home-seccion-uno.component.html',
    styleUrls: ['./home-seccion-uno.component.scss'],
    animations: [
        trigger('fadeAnimation', [
            state('in', style({ opacity: 1 })),
            state('out', style({ opacity: 0 })),
            transition('out => in', [animate('0.8s ease-in')]),
            transition('in => out', [animate('0.8s ease-out')]),
        ]),
        trigger('underlineAnimation', [
            state('visible', style({ opacity: 1 })),
            state('hidden', style({ opacity: 0 })),
            transition('hidden => visible', animate('0.8s 0.5s ease-in')),
            transition('visible => hidden', animate('0.5s ease-out')),
        ]),
    ],
})
export class HomeSeccionUnoComponent {

    public stages: Stage[] = [
        {
            text: 'Transformaciones tecnológicas',
            subtext: 'en serio',
            index: 1,
            styles: {
                backgroundImage: 'url(/img/home/home-seccion-uno-bg-1.png)',
                backgoundPosition: '',
            },
        },
        {
            text: 'Desarrollamos soluciones',
            subtext: 'a medida',
            index: 2,
            styles: {
                backgroundImage: 'url(/img/home/home-seccion-uno-bg-2.png)' 
            },
        },
        {
            text: 'Potenciamos organizaciones',
            subtext: 'de todo tipo',
            index: 3,
            styles: {
                backgroundImage: 'url(/img/home/home-seccion-uno-bg-3.png)',
            },
        },
    ];
    public currentIndex: number = 0;

    public currentStage: Stage = this.stages[0];
    state: string = 'in';
    underlineState: string = 'visible';

    constructor(private cdr: ChangeDetectorRef) {
        afterNextRender(() => {
            this.startImageRotation();
        });
    }

    startImageRotation() {
        setTimeout(() => {
            this.changeContent(); // Cambio inicial
        }, 5000);
    }

    changeContent() {
        this.state = 'out'; // Inicia fade-out de imagen y texto
        this.underlineState = 'hidden'; // Oculta subrayado
        this.cdr.markForCheck(); // Forzar detección de cambios
        setTimeout(() => {
            this.currentIndex = (this.currentIndex + 1) % this.stages.length;

            this.currentStage = this.stages[this.currentIndex];
            this.state = 'in'; // Inicia fade-in de imagen y texto
            this.cdr.markForCheck(); // Forzar detección de cambios
            setTimeout(() => {
                this.underlineState = 'visible'; // Aparece el subrayado
                this.cdr.markForCheck(); // Forzar detección de cambios
                this.startImageRotation(); // Reinicia el ciclo
            }, 1000);
        }, 1000); // Tiempo para que termine el fade-out
    }
}
