<section class="contenedor">
    <div class="contenido">
        <div>
            <div>
                <h2>Toda gran historia <em>tiene 3 partes </em></h2>
            </div>
            <div class="card-container">
                <app-card
                    [cardNumber]="'1'"
                    [cardTitle]="'Anteproyecto'"
                    [cardImageURL]="'img/home/home-seccion-tres-card-one.png'"
                    data-aos="fade-right"
                    data-aos-offset="200"
                    data-aos-easing="ease-in"
                >
                    <p>
                        Diagnosticar es el primer paso. Hacemos un mapeo de tu
                        organización para detectar dónde aportar un valor
                        significativo y
                        <span class="strong"> de impacto real. </span>
                    </p>
                </app-card>
                <app-card
                    [cardNumber]="'2'"
                    [cardTitle]="'Construcción del software'"
                    [cardImageURL]="'img/home/home-seccion-tres-card-two.png'"
                    data-aos="fade-right"
                    data-aos-offset="200"
                    data-aos-easing="ease-in"
                >
                    <p>
                        Desarrollamos una
                        <span class="strong"> solución a medida en cada oportunidad. </span>
                        Diseñamos desde cero un producto pensado
                        <span class="strong"> exclusivamente para vos. </span>
                    </p>
                </app-card>
                <app-card
                    [cardNumber]="'3'"
                    [cardTitle]="'Implementación'"
                    [cardImageURL]="'img/home/home-seccion-tres-card-three.png'"
                    data-aos="fade-right"
                    data-aos-offset="200"
                    data-aos-easing="ease-in"
                >
                    <p>
                        Empujamos el proyecto para que sea una realidad que
                        brinde los beneficios que buscás. Nuestro
                        <span class="strong"> compromiso es con una solución funcional </span>
                        y operativa.
                    </p>
                </app-card>
            </div>
            <a class="h3" routerLink="servicio">Más sobre <em> el Método Kainet </em> </a>
        </div>
    </div>
</section>
