import { Component, HostListener, Inject, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { FooterComponent } from './base/layout/components/footer/footer.component';
import { HeaderComponent } from './base/layout/components/header/header.component';
import { filter } from 'rxjs';
import * as AOS from 'aos';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [RouterOutlet, HeaderComponent, FooterComponent],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
})
export class AppComponent {
    title = 'kainet_web';

    headerBackground = 'transparent'; // Color por defecto
    currentRoute = ''; // Ruta actual

    constructor(
        private router: Router,
        @Inject(PLATFORM_ID) private platformId: Object
    ) {}

    ngOnInit() {
    // Escuchar cambios de rutas
        if (isPlatformBrowser(this.platformId)) {
            AOS.init({
                duration: 1200, 
                once: true ,
                startEvent: 'DOMContentLoaded', 

            });
        }
        this.router.events
        .pipe(filter(event => event instanceof NavigationEnd))
        .subscribe((event: any) => {
            this.currentRoute = event.url;
            this.updateHeaderBackground(); 
            if (event instanceof NavigationEnd) {
                AOS.refresh(); 
            }
        });
    }
    
    @HostListener('window:scroll', [])
    onWindowScroll() {
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        if (scrollPosition > 100) { 
            this.headerBackground = '#1a1a1a'; 
        } else {
            this.updateHeaderBackground();
        }
    }

    updateHeaderBackground() {
        if (this.currentRoute === '/') {
            this.headerBackground = 'transparent'; 
        } else {
            this.headerBackground = '#1a1a1a'; 
        }
    }
}
