import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'app-selector-de-lenguage',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './selector-de-lenguage.component.html',
  styleUrl: './selector-de-lenguage.component.scss',
})
export class SelectorDeLenguageComponent {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public setLenguaje(element: any) {
    const el = element.target;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    el.parentElement!.childNodes.forEach((e: any) => {
      e.classList.remove('active');
    });
    el.classList.add('active');
  }
}
