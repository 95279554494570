import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { SelectorDeLenguageComponent } from './components/selector-de-lenguage/selector-de-lenguage.component';

@Component({
    selector: 'app-header',
    standalone: true,
    imports: [
        RouterLink,
        RouterLinkActive,
        CommonModule,
        SelectorDeLenguageComponent,
    ],
    templateUrl: './header.component.html',
    styleUrl: './header.component.scss',
})
export class HeaderComponent {
    public isMenuOpen = false;

    @Input()
    public headerBackground: string = 'transparent';

    public checkScreenWidth() : boolean {
        if (window.innerWidth > 920) {
            document.body.classList.remove('no-scroll'); // Remueve la clase cuando el menú se cierra
            return true
        }
        return false
    };

    public toggleMenu(isLogo: boolean = false) {
        if (isLogo) {   
            this.isMenuOpen = false;
            document.body.classList.remove('no-scroll'); // Elimina la clase si es el logo
            return;
        }
        
        this.isMenuOpen = !this.isMenuOpen;
        
        if (!this.checkScreenWidth() && this.isMenuOpen) {
            document.body.classList.add('no-scroll'); // Agrega la clase cuando el menú está abierto
        } else {
            document.body.classList.remove('no-scroll'); // Elimina la clase cuando el menú está cerrado
        }

    }
}