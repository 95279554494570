import { Component } from '@angular/core';

import { SeccionIndicadoresComponent } from '../base/seccion-indicadores/seccion-indicadores.component';
import { HomeSeccionCincoComponent } from './components/home-seccion-cinco/home-seccion-cinco.component';
import { HomeSeccionDosComponent } from './components/home-seccion-dos/home-seccion-dos.component';
import { HomeSeccionTresComponent } from './components/home-seccion-tres/home-seccion-tres.component';
import { HomeSeccionUnoComponent } from './components/home-seccion-uno/home-seccion-uno.component';
import { HomeSeccionSeisComponent } from './components/home-seccion-seis/home-seccion-seis.component';

@Component({
    selector: 'app-home',
    standalone: true,
    imports: [
        HomeSeccionUnoComponent,
        HomeSeccionDosComponent,
        HomeSeccionTresComponent,
        SeccionIndicadoresComponent,
        HomeSeccionCincoComponent,
        HomeSeccionSeisComponent,
    ],
    templateUrl: './home.component.html',
    styleUrl: './home.component.scss',
})
export class HomeComponent {}
