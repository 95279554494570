import { Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';

export const routes: Routes = [
    { path: '', component: HomeComponent },
    {
        path: 'empresa',
        loadComponent: () =>
            import('./empresa/empresa.component').then(
                (m) => m.EmpresaComponent,
            ),
    },
    {
        path: 'servicio',
        loadComponent: () =>
            import('./servicio/servicio.component').then(
                (m) => m.ServicioComponent,
            ),
    },
    {
        path: 'talento',
        loadChildren: () =>
            import('./talento/routes').then((m) => m.TALENTO_ROUTES),
    },
    {
        path: 'blog',
        loadComponent: () =>
            import('./blog/blog.component').then((m) => m.BlogComponent),
    },
    {
        path: 'desarrollos',
        loadComponent: () =>
            import('./desarrollos/desarrollos.component').then((m) => m.DesarrollosComponent),
    },
];
