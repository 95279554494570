<nav class="contenedor" [ngStyle]="{'background-color': headerBackground}">
    <div class="contenido" [ngClass]="{ open: isMenuOpen, close: !isMenuOpen }">
        <i class="mobile-elemento-oculto"></i>
        <div>
            <a routerLink="/" class="contenedorLogo" (click)="toggleMenu(true)">
                <img alt="logo" src="img/logo.svg" class="logo" />
            </a>
            <ul class="list" [ngClass]="{ active: isMenuOpen }">
                <li>
                    <a
                        class="item"
                        routerLink="/empresa"
                        routerLinkActive="active"
                        (click)="toggleMenu()"
                        >Empresa</a
                    >
                </li>
                <li>
                    <a
                        class="item"
                        routerLink="/servicio"
                        routerLinkActive="active"
                        (click)="toggleMenu()"
                        >Servicios</a
                    >
                </li>
                <li>
                    <a
                        class="item"
                        routerLink="/desarrollos"
                        routerLinkActive="active"
                        (click)="toggleMenu()"
                        >Desarrollos</a
                    >
                </li>
                <li>
                    <a
                        class="item"
                        routerLink="/talento"
                        routerLinkActive="active"
                        (click)="toggleMenu()"
                        >Talento</a
                    >
                </li>
                <!-- <li>
                    <a
                        class="item"
                        routerLink="/blog"
                        routerLinkActive="active"
                        (click)="toggleMenu()"
                        >Blog</a
                    >
                </li> -->
            </ul>
        </div>
        <button class="menu-toggle" (click)="toggleMenu()">
            <span [ngClass]="{ open: isMenuOpen }">
                <img src="img/hamburger.svg" />
            </span>
        </button>
        <!-- <div class="selector-de-lenguage-container">
            <app-selector-de-lenguage></app-selector-de-lenguage>
        </div> -->
    </div>
</nav>
