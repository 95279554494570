import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CardContainerComponent } from '../../../base/card-container/card-container.component';
import { FormularioContactoComponent } from '../../../base/formulario-contacto/formulario-contacto.component';

@Component({
    selector: 'app-home-seccion-seis',
    standalone: true,
    imports: [FormularioContactoComponent, CardContainerComponent],
    templateUrl: './home-seccion-seis.component.html',
    styleUrls: ['./home-seccion-seis.component.scss'],
})
export class HomeSeccionSeisComponent implements OnInit {
    constructor(private router: Router, private route: ActivatedRoute) {}

    ngOnInit(): void {
        this.route.fragment.subscribe((fragment) => {
            if (fragment) {
                setTimeout(() => {
                    this.scrollToFragment(fragment);
                }, 0);
            }
        });
    }

    scrollToFragment(fragment: string) {
        const section = document.getElementById(fragment);
        if (section) {
            const offset = 120; // Ajusta este valor según necesites
            const sectionPosition = section.getBoundingClientRect().top + window.pageYOffset - offset;
            window.scrollTo({
                top: sectionPosition,
                behavior: 'smooth' // Desplazamiento suave
            });
        }
    }
}
